<template>
    <div class="container-fluid">
        
		<div class="topfilter pt-4">
			<div class="col-12 my-auto title">
				{{ $t('Settings') }}
			</div>
		</div>
		
		<div class="custom-tabs setting-tabs">
			<b-tabs content-class="mt-3">
				<b-tab :title="$t('ProfileSettings')" @click="ChangeRoute('profile')" :active="($route.params.page == 'profile' || typeof $route.params.page == 'undefined') ? true : false">
					<div class="w-90"><profile-user @save="SaveDetails"></profile-user></div>
				</b-tab>
				<template v-if="(!$store.state.WhiteLabel || $store.state.IsAdmin) && !$store.state.active">
					<b-tab :title="$t('WhiteLabel')" @click="ChangeRoute('whitelabel')" :active="($route.params.page == 'whitelabel' || typeof $route.params.page == 'undefined') ? true : false" :disabled="CheckPlan">
						<div class="w-90"><white-label></white-label></div>
					</b-tab>
				</template>
				<b-tab v-if="!$store.state.active || ($store.state.active && 'send email to leads' in $store.state.permissions)" title="SMTP" @click="ChangeRoute('smtp')" :active="($route.params.page == 'smtp') ? true : false">
					<div class="w-90"><smtp></smtp></div>
				</b-tab>
				<b-tab title="Integrations" @click="ChangeRoute('integrations')" :active="($route.params.page == 'integrations') ? true : false">
					<div class="w-90"><integrations></integrations></div>
				</b-tab>

			</b-tabs>
		</div>
		
    </div>
</template>
<script>
	
	import profileUser 	from '@/views/user/settings/profile'
	import WhiteLabel	from '@/views/user/settings/WhiteLabel'
	import Smtp	from '@/views/user/settings/Smtp'
	import Integrations	from '@/views/user/settings/Integrations'
	
	export default {
		data() {
			return {
				
			}
		},
		methods: {
			SaveDetails() {
				
			},
			
			ChangeRoute( element ) {
				if(element){
					let that = this;
					that.$store.state.selectedPage = element
					that.$router.push({path:'/account/' + element}).catch((e)=>{
						if (
							e.name !== 'NavigationDuplicated' &&
							!e.message.includes('Avoided redundant navigation to current location')
						) {
							console.log(e)
						}
					});
				}
			},
		},
		components: {
			profileUser,
			WhiteLabel,
			Smtp,
			Integrations
		},
		computed: {
			
			CheckPlan() {
				let that = this
				if(that.$store.state.user.plan_name == process.env.VUE_APP_PLAN_FULL || that.$store.state.user.plan_name == process.env.VUE_APP_PLAN_SUMO ) {
					return false
				} else {
					return true
				}
			},
		},
		mounted() {
			
		},
	}
</script>
<style lang="scss">
	.setting-tabs .nav-tabs{
		width:90%;
	}
</style>